<template>
  <div>
    <main-hero
      title="Yhdistelylaina auttaa säästämään lainakuluissa"
      subtitle="Voit saada merkittäviä säästöjä yhdistelylainan avulla."
      side-title="Hae yhdistelylainaa ja vertaa!"
      :side-boxes="sideBoxes"
      survey-title="Käyttäjät ovat arvioineet"
      :breadcrumb="{'Yhdistä lainat': '/yhdista-lainat', 'Yhdistelylaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Yhdistelylaina on järkevä valinta.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Yhdistelylainalla yhdistät monta lainaa yhdeksi!
          </h2>
          <p>
            Joudutko kuukausittain maksamaan lainanlyhennyksiä useille eri lainanmyöntäjille? Jos tilanne on tämä, kannattaa harkita, kannattaisiko sinunkin ottaa yhdistelylaina.
          </p>
          <p>
            Yhdistelylaina tarkoittaa useiden eri pienlainojen, kulutusluottojen ja mahdollisten luottokorttivelkojen ja osamaksusopimusten yhdistämistä yhdeksi isommaksi lainaksi.
          </p>
          <p>
            Kun otat yhdistelylainan, hyödyt siitä muun muassa seuraavilla tavoilla:
          </p>
          <ul>
            <li>Säästät selvää rahaa, kun et joudu maksamaan tilin- ja lainanhoitokuluja sekä laskutuslisiä erikseen moneen eri paikkaan. Jo pelkistä tilinhoitomaksuista saattaa kertyä kuukaudessa merkittävä summa, mikäli lainoja on useita. </li>
            <li>Yhdistelylainalla voit löytää edullisemman koron ja pidemmän takaisinmaksuajan lainallesi. Tämä tuo joustavuutta raha-asioiden hoitoon.</li>
            <li>Kun maksettavana on vain yksi lasku, säästät myös aikaasi – et joudu muistamaan erikseen useita eräpäiviä ja käyttämään aikaa monen eri laskun maksamiseen.</li>
            <li>Oman talouden hoitaminen ja seuranta helpottuu, kun lyhennät vain yhtä yhdistelylainaa.</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Jäikö sinulle kysyttävää yhdistelylainasta?
          </h2>
          <p>
            Mikäli jokin lainahakemuksen täyttämiseen tai yhdistelylainaan liittyvä asia askarruttaa, voit aina ottaa yhteyttä asiakaspalveluumme.
          </p>
          <p>
            Saat meidät kiinni niin puhelimitse kuin myös sähköpostin tai verkkosivuiltamme löytyvän yhteydenottolomakkeen avulla. Palvelemme numerossa (09) 2311 3670 maanantaista perjantaihin 9.00-16.00. Sähköpostitiedustelut otamme vastaan osoitteeseen asiakaspalvelu@rahalaitos.fi.
          </p>
          <p>
            Jos kysyttävää ei jäänyt, täytä yhdistelylainahakemuksemme nyt – säästät sekä aikaa että rahaa!
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'YhdistaLainasi',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
        faq: () => import('~/components/layout/pages/yhdistelylainaFaq'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Säästä kuluissa',
          subtitle: 'yhdistämällä'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Yhdistele lainat',
          subtitle: 'helposti'
        },
        {
          icon: () => import('~/assets/img/icons/money.svg?inline'),
          title: 'Yhdistelylaina jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ({ store }) {
    await store.commit('loanApplication/MERGE_TO_FORM', { yhdista_lainoja: true })
  },
  head () {
    return {
      title: 'Yhdistelylaina - Säästä lainakuluissa | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Yhdistelylaina auttaa säästämään lainakuluissa. Voit saada merkittäviä säästöjä yhdistelylainan avulla. Hae yhdistelylainaa ja vertaa! Säästä kuluissa.' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/yhdistelylaina' }
      ]
    }
  }
}
</script>
