<template>
  <div class="oma">
    <section v-if="!isLoggedIn" class="section">
      <div class="container">
        <login />
      </div>
    </section>
    <template v-else>
      <section v-if="!hasOutpaid" class="section is-small">
        <div class="container">
          <div class="box">
            <h1 class="title">Tarjoukset</h1>
            <h2 class="subtitle is-hidden-touch">
              Alla tietoa saamistasi lainatarjouksista.
              <template v-if="$store.state.customer.loanType === 'business'">
                Tarjoukset tulevat lainantarjoajilta yleensä muutaman tunnin
                sisällä hakemuksen tekemisestä arkipäivisin ja viimeistään
                seuraavana arkipäivänä.
              </template>
              Lähetämme sinulle uusista lainatarjouksista tekstiviestin.
              Lainatarjousten tiedot tulevat suoraan Rahalaitoksen kumppaneilta.
              <template v-if="$store.state.customer.loanType === 'business'">
                Valittuasi lainatarjouksen palvelussamme saat tarvittavat ohjeet
                lainan nostoon ja lainantarjoaja on tarvittaessa sinuun
                yhteydessä.
              </template>
              Rahalaitos ei vastaa tarjousten tai lainasopimusten sisällöstä.
            </h2>
            <h2 class="subtitle is-hidden-desktop">
              Alla tietoa saamistasi lainatarjouksista. Tarjoukset tulevat
              sinulle muutaman tunnin sisällä arkipäivisin. Lainatarjousten
              tiedot tulevat Rahalaitoksen kumppaneilta. Rahalaitos ei vastaa
              tarjouksien sisällöstä.
            </h2>
          </div>
        </div>
      </section>
      <section class="section is-small is-top-paddingless">
        <div class="container">
          <decisions
            :decisions="decisions"
            :opportunities="opportunities"
            @needUpdate="updateData"
          />
        </div>
      </section>
      <section class="section is-small is-top-paddingless">
        <div class="container">
          <customer :co-allowed="coAllowed" />
        </div>
      </section>
      <section class="section is-small is-top-paddingless">
        <div class="container">
          <feedback />
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    customer: () => import( '~/components/login/customer' ),
    decisions: () => import( '~/components/login/decisions' ),
    login: () => import( '~/components/login/login' ),
    feedback: () => import( '~/components/login/feedback' )
  },
  layout: 'login',
  /**
   * @param context  {object} Nuxt context
   * @param _dummy    {null}   Just some dummy variable to ignroe nuxt warn
   * @param loanType {string} Switch loanType
   */
  async asyncData ( { app, store }, _dummy, loanType ) {
    const result = await app.$axios.$post( '/v1/oma/?action=show' )
    // When status has changed commit it
    if ( Object.prototype.hasOwnProperty.call( result, 'Status' ) ) {
      await store.dispatch(
        'auth/status', result.Status === 9001 ? false : result.Status
      )
    }
    // Handle data
    if ( Object.prototype.hasOwnProperty.call( result, 'Data' ) ) {
      const data = {}
      // Map fields from result
      if ( Object.prototype.hasOwnProperty.call( result.Data, 'coAllowed' ) ) {
        data.coAllowed = result.Data.coAllowed
      }
      // Store new loan type
      if ( loanType ) {
        store.commit( 'customer/SET_TYPE', loanType )
      }
      // Get current/default type from vuex store
      else {
        loanType = store.state.customer.loanType
      }
      // Get first consumer records
      if ( Object.prototype.hasOwnProperty.call( result.Data, 'consumer' ) ) {
        // Show consumer data when loanType is consumer (Default)
        if ( loanType === 'consumer' ) {
          data.decisions = Object.values( result.Data.consumer )
        }
        // If consumer is found check also business decisions and create
        // page for that
        if ( Object.prototype.hasOwnProperty.call( result.Data, 'business' ) ) {
          // Show business data when loanType is business
          if ( loanType === 'business' ) {
            data.decisions = Object.values( result.Data.business )
          }
          store.commit( 'customer/SET_BOTHTYPES', true )
        }
      }
      // If consumer not found show business decisions
      else if ( Object.prototype.hasOwnProperty.call( result.Data, 'business' ) ) {
        store.commit( 'customer/SET_TYPE', 'business' )
        data.decisions = Object.values( result.Data.business )
      }
      // Throw error to bugsnag when no data at all.
      else {
        app.$logger.warning( 'Can\'t display decisions', {
          axios: data
        } )
      }
      // Show general applicant information
      if ( Object.prototype.hasOwnProperty.call( result.Data, 'general' ) ) {
        data.general = result.Data.general
        // Set applicant if available
        if ( Object.prototype.hasOwnProperty.call( data.general, 'information' ) ) {
          store.commit( 'customer/SET_APPLICANT', data.general.information.applicant )
          // Show co-applicant information when available
          if ( data.general.information.coapplicant ) {
            store.commit( 'customer/SET_COAPPLICANT', data.general.information.coapplicant )
          }
        }
        if ( data.general.applied ) {
          store.commit( 'customer/SET_APPLIED', data.general.applied )
        }
      }
      // Show "Get more offers" button
      if ( Object.prototype.hasOwnProperty.call( result, 'Opportunities' ) ) {
        data.opportunities = result.Opportunities
      }
      return data
    }
    return {}
  },
  data () {
    return {
      decisions: [],
      general: {},
      coAllowed: null,
      giosgWatch: {
        count: 0,
        interval: null
      },
      opportunities: null
    }
  },
  head () {
    return {
      title: 'Oma Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Oma Rahalaitos palvelussa vertailet saamiasi lainatarjouksia
           ja valitset sinulle sopivimmat ehdot tarjoavan lainan. Kirjaudu
           sisään ja valitse laina.`
        }
      ]
    }
  },
  computed: {
    ...mapGetters( 'auth', [
      'isLoggedIn'
    ] ),
    hasOutpaid () {
      return Boolean(
        this.decisions.filter(
          decision => decision.state === 4
        ).length
      )
    }
  },
  watch: {
    async isLoggedIn ( status ) {
      if ( status ) {
        await this.updateData()
        this.giosgSubmit()
      }
    },
    hasOutpaid: {
      immediate: true,
      handler ( value ) {
        this.$store.commit( 'auth/HASOUTPAID', value )
      }
    },
    // Watcher for giosg chat. Try to send chat information when its available
    'general.chat' ( value ) {
      if ( value ) {
        this.giosgCheck()
      }
    }
  },
  methods: {
    /**
     * Return asyncData to load information
     */
    async updateData ( loanType ) {
      const data = await this.$options.asyncData(
        this.$root.$options.context,
        undefined,
        loanType
      )
      for ( const k in data ) {
        this[k] = data[k]
      }
    },
    giosgCheck () {
      if ( this.giosgWatch.count < 10 ) {
        this.giosgWatch.count++
        this.giosgWatch.interval = setTimeout( this.giosgCheck, 5000 )
        // Try to submit
        this.giosgSubmit()
      }
      // Try last time
      else {
        this.giosgSubmit()
        // Remove interval
        clearTimeout( this.giosgWatch.interval )
      }
    },
    giosgSubmit () {
      // Send information about applicant
      if (
        this.general &&
        this.general.chat !== '' &&
        typeof window._giosg === 'function'
      ) {
        window._giosg( () => {
          window.giosg.api.visitor.submit( this.general.chat, 'HS256' )
        } )
        // Remove interval
        clearTimeout( this.giosgWatch.interval )
      }
    }
  }
}
</script>

<style lang="scss">
  @import "~assets/scss/login";

  .oma {
    min-height: 75vh;
  }
</style>
