<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 15 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 15 000 euroa alkaen 141€/kk</h1>
    <h2>Rahalaitoksen kautta löydät edullisen lainan</h2>
    <p>Kilpailuttamalla lainan Rahalaitoksella löydät edulliset lainatarjoukset. Kun haet 15 000 euron lainaa sivustomme kautta, lähetämme hakemuksesi automaattisesti eteenpäin pankeille ja muille rahoituslaitoksille. Sinä saat <strong>yhdellä hakemuksella monta lainatarjousta</strong> ja voit valita näistä sinulle parhaiten sopivan.</p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <h2>Säästä aikaa, vaivaa ja rahaa Rahalaitoksen avulla</h2>
    <p>Sinä täytät vain yhden hakemuksen ja saat monta lainatarjousta. Kuvittele kuinka paljon aikaa sinulla menisi jos joutuisit täyttämään lainahakemuksen jokaiselle noin 15 pankille erikseen! Sen lisäksi että säästämme sinulta aikaa ja vaivaa, säästämme myös lainakuluissa - koska sinä saat täyden tiedon lainojen hinnoista ja voit <strong>valita niistä edullisimman</strong>.</p>

    <h3>Miten lainan hakeminen tapahtuu?</h3>
    <p>Teet hakemuksen <strong>helposti netissä</strong>. Siihen menee vain muutama minuutti. Aloita valitsemalla lainan suuruus (esim. 15 000 euroa) ja sen jälkeen laina-aika (2-12 vuotta). Täytä sen jälkeen omat henkilö- ja yhteystietosi, näiden tietojen lisäksi tarvitsemme myös tietoja sinun työsuhteesta ja asumismuodosta. Lähetettyäsi lomakkeen näet saamasi lainatarjoukset heti ja voit valita niistä parhaan.</p>

    <p><strong>Koska rahat maksetaan tilille?</strong> Koska Rahalaitos ei itse myönnä lainoja, emme valitettavasti voi vaikuttaa siihen kuinka nopeasti rahat näkyvät tililläsi. Yhteistyökumppanimme lupaavat tehdä <strong>luottopäätöksen jopa 5 minuutin sisällä</strong> hakemuksesta ja parhaissa tapauksissa saat <strong>rahat tilille jo saman päivän aikana</strong>.</p>

    <h3>Millä ehdoilla voin lainata 15 000 euroa?</h3>
    <p>Kaikki yhteistyökumppanimme harjoittavat vastuullista luotonantoja eivätkä he myönnä lainaa kenelle tahansa. Koska lainaaminen tapahtuu <strong>ilman vakuuksia tai takaajia</strong>, ovat he asettaneet nämä perusehdot lainan myöntämiselle. Jotta voit saada 15000 euron lainan, tulee sinun täyttää nämä ehdot:</p>
    <ul>
      <li>Sinulla on yli 600 € kuukausitulot,</li>
      <li>Olet vähintään 20-vuotias,</li>
      <li>Sinulla ei ole julkisia maksuhäiriömerkintöjä.</li>
    </ul>
    <h3>15 000 euron laina ilman vakuuksia tai takaajia</h3>
    <p>Voit lainata 15000 euroa <nuxt-link to="/lainaa-ilman-vakuuksia">ilman vakuuksia</nuxt-link> tai takaajia. Luotonantaja tarkistaa luottotietosi ja maksukykysi ennen lainan myöntämistä (tästä syystä kysymme mm. asumismuodostasi ja työsuhteestasi). Tämä vaikuttaa osittain siihen että lainan hakeminen Rahalaitoksen kautta on helpompaa ja <strong>nopeampaa kuin tavallisen vakuudellisen lainan</strong> hakemisessa pankista.</p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'Lainaa15000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  data () {
    return {
      form: {
        luottoraja: 15000,
        maksuaika: 7
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 20 000 euroa',
        subtitle: 'Ilman vakuuksia ja takaajia',
        link: '/lainaa-20000-euroa'
      },
      {
        title: 'Pikalaina',
        subtitle: 'Lainaa nopeasti äkillisiin tarpeisiin',
        link: '/pikalaina'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 15000 euroa ilman vakuuksia | Hinta alkaen 141 €/kk',
      meta: [
        { hid: 'description', name: 'description', content: 'Rahalaitos kilpailuttaa pankit ja rahoituslaitokset puolestasi ilmaiseksi. Täytä hakemus jo tänään ja hae lainaa 15000 euroa!' }
      ]
    }
  }
}
</script>
