<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Kulutusluottojen korot putosivat selvästi': ''}"
    />
    <img
      style="width: 100%;"
      :src="require(
        `~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`
      )"
      :alt="this.$route.path.substring(1)"
    >
    <h1>
      Kulutusluottojen korot putosivat selvästi.
      Selvitimme lakimuutoksen hyödyt lainanhakijoille
    </h1>
    <p style="font-weight: 900;">
      Kymmenen prosentin korkokatto nimelliskoron osalta lopetti
      käytännössä korkeakorkoiset pikavippi markkinat. Rahalaitos
      teki selvityksen heinäkuussa muuttuneen korkolain vaikutuksia
      kulutusluottojen korkoihin.
    </p>
    <p>
      Heinäkuun alussa voimaan astuneen korkolain uudistuksen mukaan
      kulutusluottojen nimelliskorot rajattiin tämän vuoden loppuun
      asti maksimissaan 10 prosenttiin. Vaikka laki koskee vain
      loppuvuoden korkoja, on muutos näkynyt selvästi
      kuluttajaluottomarkkinassa. Monet pikavippejä tarjonneet
      toimijat ovat keskeyttäneet toimintansa ja korkotaso
      kulutusluottoja tarjoavilla pankeilla ja rahoitusyhtiöillä on
      laskenut selvästi.
    </p>
    <p>
      Monet vanhoja lainoja maksavat asiakkaamme ovat jo hyödyntäneet
      edun ja kilpailuttaneet lainansa uudestaan. Ennen heinäkuun alkua
      otettuja lainoja lakimuutos ei koske vaan lainat on kilpailutettava
      uudestaan, jos haluaa hyötyä edullisemmista koroista. Vakuudettomien
      lainojen korkokattoa laskettiin nyt jo toisen kerran vuoden aikana,
      mutta silti moni suomalainen maksaa edelleen turhan korkeita korkoja
      lainoistaan.
    </p>
    <h2>
      Kulutusluottojen korot laskivat alkuvuodesta
    </h2>
    <p>
      Rahalaitos selvitti koroissa tapahtunutta muutosta viime kuukausien
      osalta. Rahalaitos vertasi alkuvuodesta ennen lakimuutosta ja
      koronaepidemiaa palvelunsa kautta otettuja lainoja lakimuutoksen
      jälkeen otettuihin ja muutos on merkittävä.
      <nuxt-link to="/">Rahalaitoksen kilpailutuspalvelun</nuxt-link>
      kautta annettujen tarjousten keskikorko on laskenut tammikuusta
      heinäkuuhun jopa 4,22 prosenttiyksikköä. Eniten korkotaso on laskenut
      pienissä 2000 - 5000 euron lainoissa, mutta myös suuremmissa
      yli 30 000 euron kulutusluotoissa korkotaso on laskenut 1,48
      prosenttiyksikköä. Tällainen muutos korossa aiheuttaa jo todella
      merkittävän säästön lainakuluissa kuukausitasolla.
    </p>
    <p>
      Lainojen kilpailuttamisen suosio on kasvanut viime vuosina. Yhä useampi
      käyttää lainantarpeen tullessa vastaan kilpailutuspalveluita
      varmistaakseen parhaan mahdollisen koron luotolle. Rahalaitoksen
      palvelussa voidaan nopeasti yhdellä hakemuksella hakea lainatarjoukset
      jopa 25 pankilta. Kilpailutuspalvelussa lainatarjousten vertaileminen
      on helppoa ja nopeaa ja palvelu on hiottu tehokkaaksi.
    </p>
    <h2>
      Lainojen yhdistelyä tehdään yhä enemmän
    </h2>
    <p>
      Rahalaitoksen käyttäjätilastoihin perustuvan selvityksen mukaan eniten
      kasvava lainamuoto kulutusluotoissa on yhdistelylainat. Suomalaisille
      on kertynyt erilaisiin tarpeisiin otettuja kulutusluottoja
      ja luottokorttivelkoja, joiden
      <nuxt-link to="/yhdista-lainat">yhdistämisellä</nuxt-link> haetaan
      säästöjä lainanhoitokuluihin.
    </p>
    <p>
      Heinäkuussa voimaan tullut korkokatto ei koske osamaksuja ja
      luottokortteja, vaan niiden korko saa myös jatkossa olla yli 10 % ja
      korkeimmillaan korko voi olla jopa 20 prosenttia. Lakimuutoksen myötä
      kulutusluotot ovatkin usein luottokortteja tai osamaksuja edullisempi
      tapa rahoittaa isompia hankintoja. Suurempia ostoksia tehtäessä kannattaa
      kilpailuttaa vakuudettomia lainoja tarjoavat pankit ja tehdä vertailu
      kaupan ehdottamaan maksutapaan, jos on aikeissa maksaa ostoksensa
      luotolla tai osissa.
    </p>
    <p>
      Lainan hakeminen ja kilpailuttaminen Rahalaitoksen kautta on ilmaista
      eikä sido vielä mihinkään, vaan tarjouksiin voi tutustua kaikessa
      rauhassa. Tarvittaessa kaikki käy kuitenkin nopeasti. Kun tarjouksen
      hyväksyy, tulevat rahat tilille nopeimmillaan jopa saman päivän aikana.
    </p>
    <p>
      <nuxt-link to="/">
        Kilpailuta lainasi Rahalaitoksella – se vie
        vain muutaman minuutin eikä maksa mitään.
      </nuxt-link>
    </p>
    <span class="miniform-header">Lainan kilpailutus 70 000 € asti</span>
    <mini-form />
    <share
      text="Korkokatto putosi nyt kymmeneen prosenttiin
        – toimi näin, jos sinulla on kulutusluottoja"
    />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'KulutusluottojenKorotPutosivatSelvasti',
  components: {
    miniForm: () => import('~/components/subpage/miniform'),
    share: () => import('~/components/subpage/share'),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: `Kulutusluottojen korot putosivat selvästi.
        – Selvitimme lakimuutoksen hyödyt lainanhakijoille.`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Kymmenen prosentin korkokatto nimelliskoron
            osalta lopetti käytännössä korkeakorkoiset pikavippi
            markkinat. Rahalaitos teki selvityksen heinäkuussa
            muuttuneen korkolain vaikutuksia kulutusluottojen korkoihin.`
        }
      ]
    }
  }
}
</script>
